import { MaybeRef, computed, Ref, unref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export enum ModalQuery {
  ActionBudgeting = 'actionBudgeting',
  ActionBudgetingEmissionsCalculator = 'ActionBudgetingEmissionsCalculator',
  ActionBudgetingValeriCalculator = 'ActionBudgetingValeriCalculator',
  ActionCreate = 'createAction',
  ActionEdit = 'editAction',
  ActionsByRoadmapView = 'actionsByRoadmapView',
  ActionsScope1 = 'actionsScope1',
  ActionsScope2 = 'actionsScope2',
  ActionsScope3 = 'actionsScope3',
  AssessmentActionLegend = 'AssessmentActionsCategoryLegend',
  AssessmentYearDelete = 'assessmentYearDelete',
  AssessmentYearSelect = 'assessmentYearSelect',
  InsightsColumnsView = 'InsightsColumnsView',
  OnboardingQuestionnaire = 'questionnaire',
  ReductionPathDefaultReduction = 'ReductionPathDefaultReduction',
  ReductionPathLinearReduction = 'ReductionPathLinearReduction',
  SettingsDeleteAccount = 'deleteAccount',
  SettingsEditPassword = 'editPassword',
  SettingsLocationsAddNewLocation = 'addNewLocation',
  SettingsUsersDeleteSingleUser = 'deleteSingleUser',
  SettingsUsersManageSingleUser = 'manageSingleUser',
}

export const modalQueryKey = 'modal'

/**
 * All necessary utilities when working with `<modal-view />` component
 * @description `<modal-view />` is a component for showing modals controlled by query params,
 * there can be only one open `<modal-view />` at a time
 * @param query {@link ModalQuery} that point to a modal that You want to control,
 * You can keep it `undefined` if You want to close "any" modal
 */
export const useModalView = (query?: MaybeRef<ModalQuery | null>) => {
  const router = useRouter()
  const route = useRoute()

  const isOpen = computed(() => route.query[modalQueryKey] === query)

  /**
   * Open modal by {@link query}
   * @description will set {@link modalQueryKey} query with {@link router.replace}
   * @description is safe for other query params
   */
  const open = () => {
    router.replace({ query: { ...route.query, [modalQueryKey]: unref(query) } })
  }

  /**
   * Close modal
   * @description will clear {@link modalQueryKey} query with {@link router.replace}
   * @description is safe for other query params
   */
  const close = () =>
    router.replace({
      query: { ...route.query, [modalQueryKey]: undefined },
    })

  /**
   * Sync ref with modal open state
   * @param shouldOpen variable that will be in sync with modal state
   * @param setShouldOpen will be called when You should update `shouldOpen` value
   * @example
   * ```ts
   * const { syncWith } = useModalView(ModalQuery.OnboardingQuestionnaire)
   * const shouldOpen = ref(false)
   * syncWith(shouldOpen, (isOpen) => {
   *  shouldOpen.value = isOpen
   * })
   * ```
   */
  // TODO: Used in one place, shouldn't be global
  const syncWith = (
    shouldOpen: Ref<boolean>,
    setShouldOpen: (isOpen: boolean) => void
  ) => {
    watch(shouldOpen, () => {
      if (shouldOpen.value === isOpen.value) {
        return
      }
      if (shouldOpen.value) {
        open()
      } else {
        close()
      }
    })

    watch(isOpen, () => {
      if (shouldOpen.value === isOpen.value) {
        return
      }
      setShouldOpen(isOpen.value)
    })
  }

  return {
    open,
    close,
    isOpen,
    syncWith,
  }
}
